module controllers {
    export module costing {

        interface ICostModelListCtrlScope extends ng.IScope {


        }

        interface ICostModelListParams extends ng.ui.IStateParamsService {
            FilterList: interfaces.applicationcore.IKeyValue[];
            SearchList: interfaces.applicationcore.IKeyValue[];
        }

        export class CostModelListCtrl {
            static $inject = ["$scope",
                "$rootScope",
                "generalService",
                "$q",
                "bsLoadingOverlayService",
                "entityService",
                "$timeout",
                '$uibModal',
                'costModelService',
                '$state',
                '$stateParams',
                '$transitions',
                'statusService',
                'classificationValueService'
            ];

            CostModelSearch: interfaces.costing.ICostModelSearch;

            IsLoading: boolean = false;
            ShowGrid: boolean = false;
            vatId: number = 0;

            gridApi: uiGrid.IGridApi;
            allDirtyRows: Array<interfaces.costing.ICostModelDisplay>;
            searchAccordian: boolean = false;

            paginationOptions = {
                pageNumber: 1,
                pageSize: 25
            };

            ctrlName: string;

            sortName: string;
            sortDirection: string;
       
            ownerEntityId: number;

            apiList: uiGrid.IGridApi;
            selected: uiGrid.IGridRow;

            items: string[];
            entity: string;
            currentEntity: interfaces.applicationcore.IEntity;

            selectedRowsArray: Array<interfaces.costing.ICostModelDisplay> = [];

            filterNames: string[] = ["EntityCode",
                "Code",
                "Description",
                "TypeClassification",                         
                "IsActive"];

            filterList: interfaces.applicationcore.IKeyValue[] = [];



            constructor(private $scope: ICostModelListCtrlScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private bsLoadingOverlayService,
                private entityService: interfaces.applicationcore.IEntityService,
                private $timeout: ng.ITimeoutService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                private CostModelService: interfaces.costing.ICostModelService,
                private $state: ng.ui.IStateService,
                private $stateParams: ICostModelListParams,
                private $transitions: ng.ui.core.ITransition,
                private statusService: interfaces.master.IStatusService,
                private classificationValueService: interfaces.applicationcore.IClassificationValueService,
            ) {
                this.loadControls();
            }

            
            createNewCostModel(){
                this.$state.go("auth.MasterData.CostModels.Update",{cosId:0});
            }


            loadCostModels() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'CostModel'
                },
                    () => {
                        var searchObject: interfaces.applicationcore.ISearchObject = {
                            filters: [],
                            sorts: []
                        }

                        angular.forEach(this.CostModelSearch, (n, key) => {
                            if (n["Id"]) {
                                searchObject.filters.push({ Name: key, Value: n.Id });
                            } else {
                                searchObject.filters.push({ Name: key, Value: n });
                            }
                        });

                        if (this.gridApi) {
                            var grid = this.gridApi.grid;
                            angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                                if (n.filters[0].term) {
                                    searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                                }

                                if (n.sort.direction) {
                                    searchObject.sorts.push({
                                        Name: n.name,
                                        SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                        SortOrder: n.sort.priority
                                    });
                                }
                            });
                        }

                        var params = {
                            connectSearch: searchObject,
                            numberRecords: this.paginationOptions.pageSize,
                            pageNumber: this.paginationOptions.pageNumber,
                        }

                        return this.CostModelService.getList().query(params, (data) => {
                            this.IsLoading = false;
                            this.gvwList.data = data;
                            this.selectedRowsArray = [];

                            if (data[0]) {
                                this.gvwList.totalItems = data[0].NumRecs;
                            } else {
                                this.gvwList.totalItems = 0;
                            }
                            this.$timeout(() => {
                                if (this.apiList) {
                                    this.getFilters();
                                    this.setFilters();
                                }
                            });

                        }
                            , (errorResponse) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                            }).$promise;
                    });

            }





            loadControls() {
                let controlPromises = [
                    this.getCurrentEntity()
                ]

                this.$q.all(controlPromises).then((data) => {

                    this.ShowGrid = true;
                    this.DoSearch();
                });

            }


            search_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;

                this.DoSearch();
            }

            showAll_click() {
                this.ShowGrid = true;
                this.IsLoading = true;
                this.searchAccordian = false;
                //this.CostModelSearch 
                this.filterList = [];

                if (this.apiList !== undefined) {
                    this.apiList.grid.clearAllFilters(true, true, true).then(() => {
                        this.apiList.grid.resetColumnSorting(null);
                        this.DoSearch();
                    });
                } else {
                    this.DoSearch();
                }
            }

            DoSearch() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }

                angular.forEach(this.CostModelSearch, (n, key) => {

                    if (n) {
                        if (n["Id"]) {

                            searchObject.filters.push({ Name: key, Value: n.Id });

                        } else {
                            searchObject.filters.push({ Name: key, Value: n });
                        }
                    }
                });

                if (this.apiList) {
                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }

                    });
                }

                if (this.currentEntity)
                    this.entity = this.currentEntity.Code;

                var params = {
                    connectSearch: searchObject,
                    numberRecords: this.paginationOptions.pageSize,
                    pageNumber: this.paginationOptions.pageNumber,
                }

                this.CostModelService.getList().query(params, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = data;
                    this.selectedRowsArray = [];

                    if (data[0]) {
                        this.gvwList.totalItems = data[0].NumRecs;
                    } else {
                        this.gvwList.totalItems = 0;
                    }


                    this.$timeout(() => {
                        if (this.apiList) {
                            this.getFilters();
                            this.setFilters();
                        }
                    });


                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }



    

            downloadToExcel() {
                this.IsLoading = true;

                var searchObject: interfaces.applicationcore.ISearchObject = {
                    filters: [],
                    sorts: []
                }
                angular.forEach(this.CostModelSearch, (n, key) => {

                    if (n["Id"]) {
                        searchObject.filters.push({ Name: key, Value: n.Id });
                    } else {
                        searchObject.filters.push({ Name: key, Value: n });
                    }
                });

                if (this.apiList) {

                    var grid = this.apiList.grid;

                    angular.forEach(grid.columns, (n: uiGrid.IGridColumn) => {
                        if (n.filters[0].term) {
                            searchObject.filters.push({ Name: n.name, Value: n.filters[0].term });
                        }

                        if (n.sort.direction) {
                            searchObject.sorts.push({
                                Name: n.name,
                                SortDirection: n.sort.direction === "asc" ? 0 : 1,
                                SortOrder: n.sort.priority
                            });
                        }
                    });
                }

                var params = {
                    connectSearch: searchObject,
                    items: this.items,
                    entityCode: this.entity,
                }

                this.CostModelService.getCostModelDisplayExcel(params).then(() => {
                    this.IsLoading = false;
                }, (data) => {
                    this.IsLoading = false;
                    this.gvwList.data = [];
                    this.gvwList.totalItems = 0;
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>data.data);
                });
            }

            getCurrentEntity(): ng.IPromise<boolean> {
                let deferred = this.$q.defer<boolean>();

                this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    deferred.resolve(true);

                }, (failureData) => {

                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    deferred.resolve(true);
                });

                return deferred.promise;
            }



            loadEntities(searchText: string) {
                return this.entityService.getDropdownList(searchText).query(
                    () => {
                    }, (failureData) => {
                    }).$promise;
            }





            getFilters() {
                _.forEach(this.filterNames, (name: string) => {
                    if (this.apiList.grid.getColumn(name).filters[0].term || this.apiList.grid.getColumn(name).filters[0].term == "") {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', name));

                        if (keyValue) {
                            var index = _.findIndex(this.filterList, (o) => { return o.key === name });

                            this.filterList[index] = <interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            };
                        }
                        else {
                            this.filterList.push(<interfaces.applicationcore.IKeyValue>{
                                key: name,
                                value: this.apiList.grid.getColumn(name).filters[0].term
                            });
                        }
                    }
                });
            }

            setFilters() {
                if (this.filterList) {
                    _.forEach(this.filterList, (keyValue: interfaces.applicationcore.IKeyValue) => {
                        this.apiList.grid.getColumn(keyValue.key).filters[0].term = keyValue.value;
                    });
                }

                if (this.sortName && this.sortDirection) {
                    this.apiList.grid.getColumn(this.sortName).sort.direction = this.sortDirection;
                }
            }

            ListPageChange(newPage, pageSize) {
                this.paginationOptions.pageNumber = newPage;
                this.paginationOptions.pageSize = pageSize;

                this.DoSearch();
            }

            ListShortChange(gridApi) {
                this.DoSearch();
            }

            ListFilterChange(gridApi: uiGrid.IGridApi) {
                _.forEach(gridApi.grid.columns, (c) => {
                    if (!c.filters[0].term) {
                        var keyValue: interfaces.applicationcore.IKeyValue = _.find(this.filterList, _.matchesProperty('key', c.field));

                        if (keyValue) {
                            this.filterList.splice(this.filterList.indexOf(keyValue), 1);
                        }
                    }
                });

                this.DoSearch();
            }


            registerGridApi(gridApi: uiGrid.IGridApi) {
                this.apiList = gridApi;

                this.apiList.core.on.filterChanged(this.$scope, () => { this.ListFilterChange(gridApi) });
                this.apiList.core.on.sortChanged(this.$scope, (grid, sortColumns) => {
                    this.ListShortChange(gridApi);

                    if (sortColumns[0]) {
                        this.sortName = sortColumns[0].name;
                        this.sortDirection = sortColumns[0].sort.direction;
                    }
                    else {
                        this.sortName = "";
                        this.sortDirection = "";
                    }
                });
                this.apiList.pagination.on.paginationChanged(this.$scope, (newPage, pageSize) => { this.ListPageChange(newPage, pageSize) });

                this.apiList.core.on.rowsRendered(this.$scope, () => {

                    for (let selectedRow of this.selectedRowsArray) {

                        for (var row of this.gvwList.data) {

                            if (selectedRow.Id === row.Id) {
                                this.apiList.selection.selectRow(row);
                            }
                        }
                    }
                });


                this.$timeout(() => {
                    if (this.selected)
                        this.apiList.selection.selectRow(this.selected.entity);
                });
            }


            gvwList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: true,
                enableCellEdit: false,
                useExternalSorting: true,
                multiSelect: false,
                enableColumnResizing: true,
                enableFullRowSelection: true,
                paginationPageSizes: [25, 50, 75, 100],
                paginationPageSize: 25,
                useExternalPagination: true,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: 2,
                enableVerticalScrollbar: 1,
                onRegisterApi: (gridApi) => { this.registerGridApi(gridApi) 
                
                    this.apiList.cellNav.on.navigate(this.$scope, (newCol) => {
                        this.$timeout(() => {
                            this.apiList.selection.selectRow(newCol.row.entity);
                        });
                    });

                },
                columnDefs: [{
                    name: "ID",
                    displayName: "ID",
                    field: "Id",
                    visible: false,
                    enableFiltering: false
                }, {
                    name: "EDIT",
                    field: "Id",
                    displayName: "",
                    enableFiltering: false,
                    cellTemplate: `
                    <div> 
                    <button type="button" ui-sref="auth.MasterData.CostModels.Update({ cosId: MODEL_COL_FIELD })" class="btn btn-default btn-sm">
                        <span class="fa fa-pencil"></span>
                    </button>
                    </div>`,
                    enableSorting: false,
                    enableColumnMenu: false,
                    width: 35
                }, {
                    name: "EntityCode",
                    displayName: "Entity",
                    field: "EntityCode",
                    width: 80,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "Code",
                    displayName: "Code",
                    field: "Code",
                    width: 160,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                }, {
                    name: "Description",
                    displayName: "Description",
                    field: "Description",
                    width: 260,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                 {
                    name: "TypeClassification",
                    displayName: "Type",
                    field: "TypeClassification",
                    width: 160,
                    cellTemplate: '<div class="ui-grid-cell-contents" data-toggle="tooltip" data-placement="top" title="{{MODEL_COL_FIELD}}">{{grid.getCellValue(row, col)}}</div>',
                    filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="text" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                </div>
                            </div>`
                },
                 {
                    name: "IsActive",
                    displayName: "Is Active",
                    field: "IsActive",
                    width: 100,
                        cellTemplate: '<input type="checkbox" ng-disabled="true" ng-model="MODEL_COL_FIELD" />',
                        filterHeaderTemplate: `
                            <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                <div class="input-group-sm">
                                    <input type="checkbox" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/></div>
                            </div>`
                }
                ]
            };


            editCostModel(cosId:number)
            {
            }


        }

        angular.module("app").controller("CostModelListCtrl", controllers.costing.CostModelListCtrl);
    }
}